<h4 mat-dialog-title class="forgotPwdText"><strong>Forgot Password..!</strong> </h4>

<div mat-dialog-content >
        <div class="row" *ngIf="action != 'delete'; else elseTemplate">
    <form [formGroup]="changePwd">
        <div class="row  m-1">
                <div class="col-lg-12">
                       Please enter your User Name, password reset link will be sent to registered email address
                    <mat-form-field class="width-80 validations" appearance="outline">
                            
                        <mat-label>User Name </mat-label>
                        <input matInput required placeholder="User Name" formControlName="userName" [(ngModel)]="local_data.userName" (keyup)="forgotPwd()">
                    </mat-form-field>
                    <div *ngIf="f.userName.touched && f.userName.invalid" class="error-color">
                        <div *ngIf="f.userName.errors.required">User Name is required.</div>
                        <div *ngIf="f.userName.errors?.invalid">Please Enter Valid User Name</div>
                    </div>
                    </div>
                        </div> 
                        
                   
                    <!-- <button mat-button (click)="closeDialog()" mat-flat-button color="warn">Cancel</button> -->
   </form>
</div>
   <ng-template #elseTemplate>
        Sure to delete <b>{{local_data.username}}</b>?
      </ng-template>

</div>

<div mat-dialog-actions class="submitButtonContainer">
    <!-- <button mat-button (click)="onNoClick()">Submit</button> -->
    <button mat-button mat-flat-button class="submitButton" [disabled]="!changePwd.valid" (click)="onNoClick()">Submit</button>
    
</div>